export type RFITableItem = {
  id: number;
  rfi_id: string;
  subject: string;
  responsibility?: {
    name: string;
    company: string;
  };
  originator?: {
    name: string;
    company: string;
  };
  due: string;
  remaining_percent: number; // percent
  remaining_days: number;
  status: (typeof RFI_STATUSES)[number];
  tags: number;
};

export enum RFI_STATUS {
  OPEN = 'open',
  CLOSED = 'closed',
  VOID = 'void',
  DRAFT = 'draft',
}

export const RFI_STATUSES = [RFI_STATUS.OPEN, RFI_STATUS.CLOSED, RFI_STATUS.VOID, RFI_STATUS.DRAFT];

export enum CREATE_RFI_PAGE {
  FIRST_PAGE = 1,
  SECOND_PAGE = 2,
}

export type CreateRFIModel = {
  // todo: extend this model
  cost_impact: 'yes' | 'no';
  schedule_impact: 'yes' | 'no';
};
